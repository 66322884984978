













import Vue from 'vue'
import Dashboard from '@/components/Dashboard/index.vue'

export default Vue.extend({
  name: 'App',
  components: { Dashboard },
  data () {
    return {
      loading: true,
      dashboardId: null,
      hiddenErrors: [
        'record_constraint_forbidden',
        'record_constraint_notify'
      ]
    }
  },
  methods: {
    showCookie () {
      const str = document.cookie
      const pattern = /is_cookies_agreed=true/
      const isAgreed = pattern.test(str)
      if (!isAgreed) {
        this.showMsgCookie()
      }
    },
    showMsgCookie () {
      // +1 день от текущей даты
      let date: string = new Date(Date.now() + 86400e3).toUTCString()
      this.$alert(`
      Настоящий ресурс использует файлы "cookie" с целью сбора технических данных посетителей для обеспечения
      работоспособности и улучшения качества обслуживания. Продолжая использовать ресурс,
      Вы автоматически соглашаетесь с использованием данных технологий`,
      {
        confirmButtonText: 'Принимаю',
        roundButton: true,
        showClose: false,
        callback: action => {
          document.cookie = `is_cookies_agreed = true; expires= ${date}`
        }
      })
    }
  },
  async mounted () {
    if (this.$config.user_cookie) {
      this.showCookie()
    }
    const dashboardId = await this.$http.post(`${this.$config.api}/interfaceeditor/dashboards/portal`,
      {
        domain: window.location.host
      }, {
        hideNotification: true
      }).finally(() => {
      this.loading = false
    })

    if (Number.isInteger(dashboardId.data)) {
      this.dashboardId = dashboardId.data
    }
  },
  created () {
    this.$http.interceptors.response.use((response) => {
      if (response.config.method !== 'get' && !response.config.hideNotification) {
        this.$notify({
          title: this.$locale.main.message.success,
          message: this.$locale.main.message.saved,
          type: 'success',
          duration: 2000
        })
      }
      return response
    }, (error) => {
      let me = this
      return new Promise(function (resolve, reject) {
        if (error.config && !error.config.__isRetryRequest) {
          switch (error.response.status) {
            case 400:
              if (!error.response.config.hideNotification && !me.hiddenErrors.includes(error.response.data.error)) {
                if (
                  typeof error.response.data.code !== 'undefined' &&
                  error.response.data.code !== 0 &&
                  error.response.data.type === 'client'
                ) {
                  let title = me.$locale.errors[error.response.data.code] || error.response.data.code
                  me.$confirm(error.response.data.message, title, {
                    type: 'error',
                    showCancelButton: false
                  })
                } else {
                  let message = me.$locale.main.message.not_saved
                  if (error.response.data.notify) {
                    message = error.response.data.message || message
                  }
                  me.$notify.error({
                    title: me.$locale.main.message.error,
                    message: message,
                    duration: 2000
                  })
                }
              }
              break
            default:
              break
          }
        }
        throw error
      })
    })
  }
})
